<template>
  <div>
    <div class="row align-items-center">
      <div class="col-auto print" v-if="siteLogo">
        <img v-bind:src="siteLogo" alt="" width="125" />
      </div>
      <div class="col">
        <div class="row">
          <!-- Sipariş Detay Tablosu -->
          <div class="col-12 col-xl mb-3 mb-xl-0">
            <table class="table table-bordered mb-0">
              <tr scope="row">
                <th width="25%">Sipariş No</th>
                <td scope="col">{{ orderID }}</td>
              </tr>
              <tr scope="row">
                <th width="35%">Ödeme Türü<br />Ref. No</th>
                <td scope="col">
                  {{ getPaymentTypeName }}<br />{{ order.bank_company }}<br/>{{ order.ref_no }} 
                </td>
              </tr>
              <tr scope="row" v-if="getCargoName">
                <th width="25%">Kargo Firması</th>
                <td scope="col">{{ getCargoName }}</td>
              </tr>

              <!-- Order Attachment -->
              <template v-if="order.attachment">
                <tr scope="row" v-if="order.attachment.delivery_date">
                  <th width="25%">Teslimat Tarihi</th>
                  <td scope="col">
                    {{ order.attachment.delivery_date | dateFormat }}
                  </td>
                </tr>
                <tr
                  scope="row"
                  v-if="
                    order.attachment.delivery_start_time &&
                    order.attachment.delivery_due_time
                  "
                >
                  <th width="25%">Teslimat Saati</th>
                  <td scope="col">
                    {{ order.attachment.delivery_start_time }} -
                    {{ order.attachment.delivery_due_time }}
                  </td>
                </tr>
              </template>
              <!-- ./Order Attachment -->

              <tr scope="row" v-if="order.gift_wrap">
                <th width="25%">Hediye Paketi</th>
                <td scope="col">Evet</td>
              </tr>
              <tr scope="row" v-if="order.order_note">
                <th width="25%">Sipariş Notu</th>
                <td scope="col">{{ order.order_note }}</td>
              </tr>
              <tr scope="row" v-if="order.gift_note">
                <th width="25%">Hediye Notu</th>
                <td scope="col">{{ order.gift_note }}</td>
              </tr>
              <tr scope="row" v-if="order.coupon_code">
                <th width="25%">Kupon Kodu</th>
                <td scope="col">{{ order.coupon_code }}</td>
              </tr>
              <tr scope="row" v-if="order.campaign && order.campaign.code">
                <th width="25%">Kampanya Kodu</th>
                <td scope="col">
                  {{ order.campaign.code }}
                </td>
              </tr>
            </table>
          </div>
          <!-- Sipariş Detay Tablosu -->

          <!-- Sipariş Barkod Görseli -->
          <div class="col-12 col-xl-auto text-center" v-if="order.barcodeImage">
            <img v-bind:src="order.barcodeImage" class="img-fluid" />
          </div>
          <!-- ./Sipariş Barkod Görseli -->
        </div>
      </div>
    </div>

    <!-- Products -->
    <WeTable
      class="products-table"
      v-bind:index="false"
      v-bind:data="order.products"
      v-bind:columns="productColumns"
      v-bind:actions="[]"
      v-bind:searchable="false"
      v-bind:pageDetails="false"
      v-bind:selectable="false"
      v-bind:limitable="false"
      v-bind:paginatable="false"
      v-bind:row-class="'font-14'"
      v-bind:responsive="false"
      v-bind:quantity="true"
    >
      <template slot="footer">
        <tr>
          <td
            v-bind:colspan="productColumns.length"
            class="border-0 text-right pt-0"
          >
            <div class="row">
              <div class="col">
                <span class="font-weight-bold">Toplam:</span>
              </div>
              <div class="col-2">{{ order.total_price }}</div>
            </div>
          </td>
        </tr>
        <tr>
          <td
            v-bind:colspan="productColumns.length"
            class="border-0 text-right pt-0"
          >
            <div class="row">
              <div class="col">
                <span class="font-weight-bold">KDV:</span>
              </div>
              <div class="col-2">{{ order.total_tax }}</div>
            </div>
          </td>
        </tr>
        <tr>
          <td
            v-bind:colspan="productColumns.length"
            class="border-0 text-right pt-0"
          >
            <div class="row">
              <div class="col">
                <span class="font-weight-bold">Ara Toplam:</span>
              </div>
              <div class="col-2">{{ order.sub_total }}</div>
            </div>
          </td>
        </tr>
        <tr>
          <td
            v-bind:colspan="productColumns.length"
            class="border-0 text-right pt-0"
          >
            <div class="row">
              <div class="col">
                <span class="font-weight-bold">Kargo:</span>
              </div>
              <div class="col-2">{{ order.total_cargo }}</div>
            </div>
          </td>
        </tr>
        <tr>
          <td
            v-if="order.coupon_discount"
            v-bind:colspan="productColumns.length"
            class="border-0 text-right pt-0"
          >
            <div class="row text-danger">
              <div class="col">
                <span class="font-weight-bold">Kupon İndirimi:</span>
              </div>
              <div class="col-2">- {{ order.coupon_discount }}</div>
            </div>
          </td>
        </tr>
        <tr>
          <td
            v-if="order.campaign_discount"
            v-bind:colspan="productColumns.length"
            class="border-0 text-right pt-0"
          >
            <div class="row text-danger">
              <div class="col">
                <span class="font-weight-bold">Kampanya İndirimi:</span>
              </div>
              <div class="col-2">- {{ order.campaign_discount }}</div>
            </div>
          </td>
        </tr>
        <tr>
          <td
            v-if="order.bank_transfer_discount"
            v-bind:colspan="productColumns.length"
            class="border-0 text-right pt-0"
          >
            <div class="row text-danger">
              <div class="col">
                <span class="font-weight-bold">Havale İndirim:</span>
              </div>
              <div class="col-2">- {{ order.bank_transfer_discount }}</div>
            </div>
          </td>
        </tr>
        <tr v-if="order.installment && order.installment > 0">
          <td
            v-bind:colspan="productColumns.length"
            class="border-0 text-right pt-0"
          >
            <div class="row text-danger">
              <div class="col">
                <span class="font-weight-bold">Vade Farkı:</span>
              </div>
              <div class="col-2">
                {{ order.installment_amount | moneyFormat }}
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td
            v-bind:colspan="productColumns.length"
            class="border-0 text-right pt-0"
          >
            <div class="row">
              <div class="col">
                <span class="font-weight-bold">Genel Toplam:</span>
              </div>
              <div class="col-2">{{ order.total_amount }}</div>
            </div>
          </td>
        </tr>
      </template>
    </WeTable>
    <!-- Products -->
  </div>
</template>
<script>
export default {
  name: "DetailTab",
  data() {
    return {
      productColumns: [
        { name: "image", icon: "fas fa-camera", type: "image" },
        { name: "sku_no", th: "Stok Kodu", type: "string" },
        { name: "name", th: "Ürün Adı", type: "html" },
        { name: "quantity", th: "Miktar", type: "integer" },
        { name: "price", th: "Birim Fiyatı", type: "currency" },
        { name: "total_price", th: "Toplam Fiyatı", type: "total_price" },
      ],
    };
  },
  props: {
    order: {
      type: Object,
      default: () => {},
    },
    siteLogo: {
      default: null,
    },
  },
  computed: {
    orderID() {
      return this.order.order_no || "-";
    },
    getCargoName() {
      const cargo = this.order.cargo;
      let cargoCompanyName = cargo?.name;
      if (cargo && cargo.cargo_company) {
        cargoCompanyName = cargo.cargo_company.name;
      }
      return cargoCompanyName;
    },
    getPaymentTypeName() {
      let result = this.order.payment_type;

      if (result == "Kredi Kartı") {
        const installment = this.order.installment;

        let installmentText = "Tek Çekim";

        if (installment > 0) {
          installmentText = `${installment} Taksit`;
        }

        result = `${result} (${installmentText})`;

        if (this.order.selected_bank && this.order.selected_bank.bank) {
          const bank = this.order.selected_bank.bank;
          const bankType = this.order.selected_bank.type;
          if (bankType == "paymentIntegrationCompany") {
            result = `${bank.name} ${result}`;
          }
        } else {
          result = `${result} ${
            this.order.bank_result.bank ? "-" + this.order.bank_result.bank : ""
          }`;
        }
      }

      return result;
    },
  },
};
</script>
