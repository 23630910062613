<template>
  <div>
    <!-- Shipment Address -->
    <div class="card mb-3 print-borderless" v-if="customer.shipment">
      <div class="card-header">
        <h6 class="mb-0">Teslimat Bilgileri</h6>
      </div>
      <div class="card-body">
        <table class="table table-borderless">
          <tr scope="row">
            <th width="25%">Adı Soyadı</th>
            <td scope="col">{{ customer.shipment.name }}</td>
          </tr>
          <tr scope="row" v-if="customer.shipment.phone">
            <th width="25%">Telefon</th>
            <td scope="col">{{ customer.shipment.phone }}</td>
          </tr>
          <tr scope="row" v-if="customer.shipment.gsm">
            <th width="25%">Cep Telefonu</th>
            <td scope="col">{{ customer.shipment.gsm }}</td>
          </tr>
          <tr scope="row">
            <th width="25%">E-Posta Adresi</th>
            <td scope="col">{{ customer.shipment.email }}</td>
          </tr>
          <tr scope="row">
            <th width="25%">Teslimat Adresi</th>
            <td scope="col" v-html="shipmentAddress"></td>
          </tr>
        </table>
      </div>
    </div>
    <!-- Shipment Address -->

    <!-- Invoice Address -->
    <div class="card print-borderless" v-if="customer.invoice">
      <div class="card-header">
        <h6 class="mb-0">Fatura Bilgileri</h6>
      </div>
      <div class="card-body">
        <table class="table table-borderless">
          <tr scope="row">
            <th width="25%">Adı Soyadı</th>
            <td scope="col">{{ customer.invoice.name }}</td>
          </tr>
          <template v-if="customer.invoice.vkn">
            <tr scope="row">
              <th width="25%">Firma Adı</th>
              <td scope="col">{{ customer.invoice.company }}</td>
            </tr>
            <tr scope="row">
              <th width="25%">Vergi Dairesi</th>
              <td scope="col">{{ customer.invoice.tax_office }}</td>
            </tr>
          </template>
          <tr scope="row" v-if="getIdentity && getIdentity.length">
            <th width="25%">{{ identityLabel }}</th>
            <td scope="col">{{ getIdentity }}</td>
          </tr>
          <tr scope="row" v-if="customer.invoice.phone">
            <th width="25%">Telefon</th>
            <td scope="col">{{ customer.invoice.phone }}</td>
          </tr>
          <tr scope="row" v-if="customer.invoice.gsm">
            <th width="25%">Cep Telefonu</th>
            <td scope="col">{{ customer.invoice.gsm }}</td>
          </tr>
          <tr scope="row">
            <th width="25%">E-Posta Adresi</th>
            <td scope="col">{{ customer.invoice.email }}</td>
          </tr>
          <tr scope="row">
            <th width="25%">Fatura Adresi</th>
            <td scope="col" v-html="invoiceAddress"></td>
          </tr>
        </table>
      </div>
    </div>
    <!-- Invoice Address -->
  </div>
</template>
<script>
export default {
  name: "InvoiceTab",
  props: {
    order: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    shipmentAddress() {
      const shipment = this.order.shipment;
      let result = "";
      if (shipment.neighborhood) {
        result += shipment.neighborhood + " ";
      }
      if (shipment.address) {
        result += shipment.address;
      }
      if (shipment.postal_code) {
        result += " - " + shipment.postal_code + " ";
      }
      if (shipment.district) {
        result += shipment.district + " ";
      }
      if (shipment.city) {
        result += " / " + shipment.city + " ";
      }
      if (shipment.country) {
        result += shipment.country;
      }

      return helper.ucfirst(result, true);
    },
    invoiceAddress() {
      const invoice = this.order.invoice;
      let result = "";

      if (invoice.neighborhood) {
        result += invoice.neighborhood + " ";
      }
      if (invoice.address) {
        result += invoice.address;
      }
      if (invoice.postal_code) {
        result += " - " + invoice.postal_code + " ";
      }
      if (invoice.district) {
        result += invoice.district + " ";
      }
      if (invoice.city) {
        result += " / " + invoice.city + " ";
      }
      if (invoice.country) {
        result += invoice.country;
      }

      return helper.ucfirst(result, true);
    },
    customer() {
      return this.order.customer;
    },
    identityLabel() {
      return this.customer.invoice.vkn ? "VKN" : "T.C No";
    },
    getIdentity() {
      return this.customer.invoice.vkn || this.customer.invoice.tcn;
    },
  },
};
</script>
